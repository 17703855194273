// リセット
body {
  color: #000;
}

a {
  color: #000;
  text-decoration: none;
}

img {
  vertical-align: bottom;
}

// 共通
.container {
  padding-left: 20px;
  padding-right: 20px;
}

// pc, spの切り替え
.sp {
  display: none !important;

  @media only screen and (max-width: 767px) {
    display: block !important;
  }
}

.sp-i {
  display: none !important;

  @media only screen and (max-width: 767px) {
    display: inline !important;
  }
}

.pc {
  display: block !important;

  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}

.pc-i {
  display: inline !important;

  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}

// ヘッダー
.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  font-family: Helvetica, 'Neue Haas Grotesk', sans-self;
}

// トグルボタンとそのアニメーション
.toggle {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 50px;
  height: 44px;
  cursor: pointer;
  z-index: 30;
  transform-origin: left top;
  transform: scale(.6);

  @media only screen and (max-width: 767px) {
    transform: scale(.4);
    top: 19px;
    left: 19px;
  }
}

.toggle span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #fff;
}

.toggle, .toggle span {
  display: inline-block;
  transition: all .5s;
  box-sizing: border-box;
}

.toggle span:nth-of-type(1) {
  top: 0;
}

.toggle span:nth-of-type(2) {
  top: 20px;
}

.toggle span:nth-of-type(3) {
  bottom: 0;
}

.toggle.active span:nth-of-type(1) {
  -webkit-transform: translateY(20px) rotate(-45deg);
  transform: translateY(20px) rotate(-45deg);
}

.toggle.active span:nth-of-type(2) {
  left: 50%;
  opacity: 0;
  -webkit-animation: active-btn05-bar02 .8s forwards;
  animation: active-btn05-bar02 .8s forwards;
}

@-webkit-keyframes active-btn05-bar02 {
  100% {
    height: 0;
  }
}

@keyframes active-btn05-bar02 {
  100% {
    height: 0;
  }
}

.toggle.active span:nth-of-type(3) {
  -webkit-transform: translateY(-20px) rotate(45deg);
  transform: translateY(-20px) rotate(45deg);
}

// ロゴ
.header__logoLink {
  width: 160px;
  position: absolute;
  top: 16px;
  right: 20px;
  display: block;
  border-bottom: 2px solid #fff;
  z-index: 100;

  @media only screen and (max-width: 767px) {
    width: 113px;
    top: 15px;
    right: 19px;
  }

  &:hover {
    opacity: 0.7;
  }
}

.header__logo {
  width: 100%;
  transform: scale(1.02);
}

// トグルの中身
.navi {
  background-color: #F5F5F5;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 20;

  a {
    transition: .1s;

    &:hover {
      opacity: 0.5;
    }
  }
}

.navi__container {
  width: 632px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 316px);
  grid-template-rows: repeat(4, 95px);
  grid-auto-flow: column;

  @media only screen and (max-width: 767px) {
    width: auto;
    height: auto;
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    position: static;
    padding-top: 79px;

    > li {
      margin-bottom: 24px;
    }
  }
}

.navi__link {
  font-size: 41px;

  @media only screen and (max-width: 767px) {
    font-size: 32px;
  }
}

.info {
  grid-column: 2 / 3;
  grid-row: 2 / 4;
}

.info__title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;

  @media only screen and (max-width: 767px) {
    font-size: 24px;
    font-weight: normal;
  }
}

.info__address,
.info__mail {
  font-size: 12px;
  line-height: 1.6;
  color: #969696;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.info__mail {
  margin-top: 5px;

  @media only screen and (max-width: 767px) {
    margin-top: 7px;
  }
}

.info__sns {
  margin-top: 8px;
  display: flex;

  @media only screen and (max-width: 767px) {
    margin-top: 19px;
  }

  li {
    margin-right: 15px;
  }

  img {
    width: 24px;
  }
}

.navi__logoWrap {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.navi__logoWrap:hover {
  opacity: 1 !important;
}

.navi__logo {
  width: 550px;
  position: relative;
  left: -6px;
  top: -47px;

  @media only screen and (max-width: 1110px) {
    width: 450px;
    top: -34px;
  }

  @media only screen and (max-width: 950px) {
    width: 350px;
    top: -19px;
  }
}

// トグルの中身のアニメーション
.fade-enter-active,
.fade-leave-active {
  transition: 0.4s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
